 
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { IngredientDetails } from "../ui-components/IngredientDetails";
import { Typography } from "@mui/material";

export default function Ingredients() {
  
  return (
    <>
    <AuthenticatedTemplate>
    
       <IngredientDetails></IngredientDetails>
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
        <Typography variant="h6" align="center">
          Please sign-in to see your profile information.
        </Typography>
      </UnauthenticatedTemplate>
    </>
  );
}
