import React from "react";
import Divider from "@mui/material/Divider";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import DownloadIcon from "@mui/icons-material/Download";
import HistoryIcon from "@mui/icons-material/History";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  GetAllergens,
  GetCareInstructions,
  GetConfig,
  GetIngredients,
  GetProductsList,
  GetSupplierIngredients,
  Getusername,
  PrintAuditLog,
  saveproduct,
  Syncproducts,
} from "../utils/ApiUtils";

import {
  configurationViewModel,
  productViewModel,
  ingredientViewModel,
  allergenViewModel,
  careInstructionViewModel,
  printAuditViewModel,
  supplierIngredientsViewModel,
} from "../api/product";

import PrintIcon from "@mui/icons-material/Print";
import CheckIcon from "@mui/icons-material/Check";
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";

import BrotherSdk from "bpac-js";
import { modalStyle, readonlystylebox, rowstyle } from "../styles/styles";
import moment from "moment";

<script src="https://cdn.jsdelivr.net/npm/bpac-js@2.0.3/dist/index.js"></script>;

export default function Productdetails() {
  //#region State
  const [productList, setProductList] =
    React.useState<Array<productViewModel> | null>(null);
  const [labelconfiguration, setConfiguration] =
    React.useState<configurationViewModel | null>(null);
  const [ingredientsList, setIngredientsList] = React.useState<
    Array<ingredientViewModel>
  >([]);
  const [allergenList, setAllergenList] = React.useState<
    Array<allergenViewModel>
  >([]);

  const [carelist, setCareList] = React.useState<
    Array<careInstructionViewModel>
  >([]);

  const [openWaringModal, setWarningModalOpenState] =
    React.useState<boolean>(false);
  const [editmode, setEditMode] = React.useState<boolean>(false);
  const [openAutoComplete, setopenAutoComplete] =
    React.useState<boolean>(false);
  const [value, setValue] = React.useState<productViewModel | null>(null);
  const [servingsPerPackage, setservingsperpackage] = React.useState<number>(0);
  const [batchNumber, setBatchNumber] = React.useState<string>("");
  const [packedsize, setpackedsize] = React.useState<number>(0);
  const [bestbeforedate, setbestbeforedate] = React.useState<string>("");
  const [NumberofLabels, setNumberofLabels] = React.useState<number>(0);
  const [supplierCode, setSupplierCode] = React.useState<string>("");
  const [labeltemplate, setlabeltemplate] = React.useState<string>("");

  const [SupplierFetchedItem, setSupplierFetchedItem] =
    React.useState<supplierIngredientsViewModel | null>(null);
  const [ShowSupplierFetchedModal, setSupplierFetchedModal] =
    React.useState<boolean>(false);
  const [wait, setWait] = React.useState(false);
  const [updatevendTags, setupdateVendTags] = React.useState(false);

  //#endregion
  //#region Refresh
  const refreshreadonlydata = (data: productViewModel) => {
    console.log("refreshing data");
    if (data == null) {
      setValue(null);
      return;
    }

    setValue(data);
    setpackedsize(data?.packingSize);

    setservingsperpackage(data?.packingSize / data?.servingSize);

    setBatchNumber("");

    setbestbeforedate("");
    setNumberofLabels(1);
    setSupplierCode(data.supplierCode);
    if (value?.supplierCode != null) setSupplierCode(value?.supplierCode);
    else setSupplierCode("");
    console.log("finished refreshing ");
  };

  //#endregion

  //#region Fetch On Load
  React.useEffect(() => {
    GetConfig().then((response) => {
      if (response !== null && typeof response === "object")
        setConfiguration(response);
    });
  }, [setConfiguration]);

  React.useEffect(() => {
    GetProductsList().then((response) => {
      if (response !== null && typeof response === "object")
        setProductList(response);
    });
  }, [setProductList]);

  React.useEffect(() => {
    getAllergensPromise();
  }, [setAllergenList]);

  React.useEffect(() => {
    getCareInstructionsPromise();
  }, [setCareList]);

  React.useEffect(() => {
    getIngredientsPromise();
  }, [setIngredientsList]);
  //#endregion

  //#region Event Handlers
  const handleUpdateVendTagsChange = (event: any) => {
    setupdateVendTags(event.target.checked);
  };

  function packageWeightChangedEvent(
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) {
    console.log("package weight change event");
    setpackedsize(parseInt(e.target.value));
    setservingsperpackage(parseInt(e.target.value) / value.servingSize);
  }

  const handleEditClicked = async () => {
    console.log("Edit Clicked");
    getIngredientsPromise();
    getCareInstructionsPromise();
    getAllergensPromise();

    setEditMode(true);
  };

  const printLabels = React.useCallback(
    async (
      passedvalue: productViewModel,
      copies: number,
      batchnumber: string,
      bestbefore: string,
      passedlabelconfiguration: configurationViewModel,
      passedServingsPerPackage: number
    ) => {
      console.log("printing labels");
      try {
        if (passedvalue.isFood && (batchnumber === "" || bestbefore === "")) {
          setWarningModalOpenState(true);
          return;
        }

        const tag = new BrotherSdk({
          templatePath: process.env.REACT_APP_LOCALFOLDER + labeltemplate,
        });

        if (passedvalue.isFood) {
          console.log(passedvalue.useCustomTemplate);
          if (passedvalue.useCustomTemplate) {
            console.log(" custom Food");
            tag.templatePath =
              process.env.REACT_APP_LOCALFOLDER +
              passedvalue.customTemplateFileName;
          } else {
            console.log("Food");
            tag.templatePath =
              process.env.REACT_APP_LOCALFOLDER +
              passedlabelconfiguration.foodDefaultLabelName;
          }
        } else {
          console.log("not food");
          tag.templatePath =
            process.env.REACT_APP_LOCALFOLDER +
            passedlabelconfiguration.nonFoodDefaultLabelName;
        }

        const nonFoodData = {
          ProductName: passedvalue.productName,
          Price: currencyFormat(passedvalue.price),
          BarCode: passedvalue.productSku,
        };
        const fooddata = {
          ServingSize:
            passedvalue.servingSize.toString() + passedvalue?.servingUnit,
          ServingsPerPackage: passedServingsPerPackage.toFixed(2).toString(),
          Ingredients: passedvalue.ingredients.join(","),
          ProductName: passedvalue.productName,
          Warning: passedvalue.mayContain.join(", "),
          CareInstructions: passedvalue.careInstructions.join(","),
          Description: passedvalue.productDescription,
          Price: currencyFormat(passedvalue.price),
          BarCode: passedvalue.productSku,
          BestBefore: bestbefore,
          BatchNumber: batchnumber,
          EnergyPerServing: passedvalue.energyPerServing.toFixed(1).toString(),
          FatPerServing: passedvalue.totalFatPerServing.toFixed(1).toString(),
          CarbsPerServing: passedvalue.carbohydratesPerServing
            .toFixed(1)
            .toString(),
          SugarsPerServing: passedvalue.sugarsPerServing.toFixed(1).toString(),
          ProteinPerServing: passedvalue.proteinPerServing
            .toFixed(1)
            .toString(),
          EnergyPer100: passedvalue.energyPer100.toFixed(1).toString(),
          FatPer100: passedvalue.totalFatPer100.toFixed(1).toString(),
          CarbsPer100: passedvalue.carbohydratesPer100.toFixed(1).toString(),
          SugarsPer100: passedvalue.sugarsPer100.toFixed(1).toString(),
          ProteinPer100: passedvalue.proteinPer100.toFixed(1).toString(),
          SodiumPerServing: passedvalue.sodiumPerServing.toFixed(1).toString(),
          SodiumPer100: passedvalue.sodiumPer100.toFixed(1).toString(),
        };
        const customfoodData = {
          ServingSize:
            passedvalue.servingSize.toString() + passedvalue?.servingUnit,
          ServingsPerPackage: passedServingsPerPackage.toFixed(2).toString(),
          ProductName: passedvalue.productName,
          Warning: passedvalue.mayContain.join(", "),
          Contains: "",
          CareInstructions: passedvalue.careInstructions.join(","),
          Description: passedvalue.productDescription ?? "",
          Price: currencyFormat(passedvalue.price),
          BarCode: passedvalue.productSku,
          BestBefore: bestbefore,
          BatchNumber: batchnumber,
          EnergyPerServing: passedvalue.energyPerServing.toFixed(1).toString(),
          FatPerServing: passedvalue.totalFatPerServing.toFixed(1).toString(),
          CarbsPerServing: passedvalue.carbohydratesPerServing
            .toFixed(3)
            .toString(),
          SugarsPerServing: passedvalue.sugarsPerServing.toFixed(1).toString(),
          ProteinPerServing: passedvalue.proteinPerServing
            .toFixed(3)
            .toString(),
          EnergyPer100: passedvalue.energyPer100.toFixed(1).toString(),
          FatPer100: passedvalue.totalFatPer100.toFixed(1).toString(),
          CarbsPer100: passedvalue.carbohydratesPer100.toFixed(1).toString(),
          SugarsPer100: passedvalue.sugarsPer100.toFixed(1).toString(),
          ProteinPer100: passedvalue.proteinPer100.toFixed(1).toString(),
          SodiumPerServing: passedvalue.sodiumPerServing.toFixed(1).toString(),
          SodiumPer100: passedvalue.sodiumPer100.toFixed(1).toString(),
        };
        if (passedvalue.totalFatPer100 < 0.1) fooddata.FatPer100 = "<1";
        if (passedvalue.totalFatPerServing < 0.1) fooddata.FatPerServing = "<1";

        if (passedvalue.mayContain.length > 0) {
          console.log("maycontain");
          customfoodData.Warning =
            "May Contain: " + passedvalue.mayContain.join(", ");

          fooddata.Warning =
            "May Contain: " + passedvalue.mayContain.join(", ");
        }
        console.log(passedvalue.contains);
        if (passedvalue.contains.length > 0) {
          customfoodData.Contains =
            "Contains: " + passedvalue.contains.join(", ");
        } else {
          customfoodData.Contains = "";
        }
        if (passedvalue.careInstructions.length > 0) {
          customfoodData.CareInstructions =
            "Care Instructions: " + passedvalue.careInstructions.join(",");

          fooddata.CareInstructions =
            "Care Instructions: " + passedvalue.careInstructions.join(",");
        }
        const options = {
          copies: copies, // Optional - Defaults: 1
          autoCut: true,
        };

        console.log(fooddata);
        if (passedvalue.isFood) {
          if (passedvalue.useCustomTemplate) {
            console.log(batchnumber);

            await tag.print(customfoodData, options);
            const printlog: printAuditViewModel = {
              batchNumber: customfoodData.BatchNumber,
              labels: copies,
              expiry: bestbefore,
              labelDetails: JSON.stringify(customfoodData),
              printedOn: moment(Date()).toISOString(true),
              printedBy: Getusername(),
              productId: passedvalue.id,
            };
            PrintAuditLog(printlog);
          } else {
            const printlog: printAuditViewModel = {
              batchNumber: fooddata.BatchNumber,
              expiry: bestbefore,
              labels: copies,
              labelDetails: JSON.stringify(fooddata),
              printedOn: moment(Date()).toISOString(true),
              printedBy: Getusername(),
              productId: passedvalue.id,
            };
            PrintAuditLog(printlog);
            await tag.print(fooddata, options);
          }
        } else {
          await tag.print(nonFoodData, options);
          const printlog: printAuditViewModel = {
            batchNumber: batchNumber,
            expiry: bestbefore,
            labelDetails: JSON.stringify(fooddata),
            printedOn: moment(Date()).toISOString(true),
            printedBy: Getusername(),
          };
          PrintAuditLog(printlog);
        }
      } catch (error) {
        alert(error);
        console.log({ error });
      }
    },
    [setWarningModalOpenState, setlabeltemplate]
  );

  const syncItem = (selectedValue: productViewModel) => {
    setWait(true);
    Syncproducts().then((respones) => {
      GetProductsList().then((response) => {
        if (response !== null && typeof response === "object")
          setProductList(response);
        console.log(value.price);

        if (selectedValue != null) {
          const newvalue = productList.find((element) => {
            return element.id == selectedValue.id;
          });
          console.log(newvalue.price);
          refreshreadonlydata(newvalue);
        }
        setWait(false);
      });
    });
  };

  const FetchSupplierdetails = React.useCallback(
    async (supplierid: string) => {
      const result = await GetSupplierIngredients(
        supplierid,
        supplierCode
      ).then((res: supplierIngredientsViewModel) => {
        return res;
        /*
        value.ingredients = res.ingredients;
        value.carbohydratesPer100 = res.carbohydratesValue;
        value.energyPer100 = res.energyValue;
        value.proteinPer100 = res.proteinValue;
        value.totalFatPer100 = res.fatValue;
        value.sugarsPer100 = res.sugarsValue;
        value.sodiumPer100 = res.sodiumValue;
        value.mayContain = res.mayContain.split(",");

        value.isFood = true;
         refreshreadonlydata(value);
        setValue(value);*/
        // setupdateVendTags(SupplierFetchedItem?.dietary.length > 1);
      });
      setSupplierFetchedItem(result);
      setupdateVendTags(result?.dietary.length > 1);
      setSupplierFetchedModal(true);
    },
    [
      setSupplierFetchedItem,
      setupdateVendTags,
      setSupplierFetchedModal,
      supplierCode,
    ]
  );

  const saveitem = () => {
    value.isFood = value.ingredients.length > 0;
    value.proteinPerServing = value.proteinPer100 * (value.servingSize / 100);
    value.energyPerServing = value.energyPer100 * (value.servingSize / 100);
    value.totalFatPerServing = value.totalFatPer100 * (value.servingSize / 100);
    value.carbohydratesPerServing =
      value.carbohydratesPer100 * (value.servingSize / 100);
    value.sugarsPerServing = value.sugarsPer100 * (value.servingSize / 100);
    value.sodiumPerServing = value.sodiumPer100 * (value.servingSize / 100);

    console.log(value);
    console.log(productList);
    const newlist = productList.map((item) => {
      if (item.id == value.id) {
        item.productDescription = value.productDescription;
        item.servingSize = value.servingSize;
        item.ingredients = value.ingredients;
        item.mayContain = value.mayContain;
        item.careInstructions = value.careInstructions;
        item.energyPer100 = value.energyPer100;
        item.energyPerServing = value.energyPerServing;
        item.proteinPer100 = value.proteinPer100;
        item.proteinPerServing = value.proteinPerServing;
        item.totalFatPer100 = value.totalFatPer100;
        item.totalFatPerServing = value.totalFatPerServing;
        item.carbohydratesPer100 = value.carbohydratesPer100;
        item.carbohydratesPerServing = value.carbohydratesPerServing;
        item.sugarsPer100 = value.sugarsPer100;
        item.sugarsPerServing = value.sugarsPerServing;
        item.sodiumPer100 = value.sodiumPer100;
        item.sodiumPerServing = value.sodiumPerServing;
      }

      return item;
    });

    saveproduct(value);

    setProductList(newlist);
    console.log("products List");

    refreshreadonlydata(value);
    console.log("refreshedData");
    setEditMode(false);
    setSupplierFetchedModal(false);
  };

  const handleAutoCompleteOpen = () => {
    if (productList != null) {
      setopenAutoComplete(true);
    }
  };
  //#endregion

  //#region Formatters

  function currencyFormat(num: Number) {
    if (num)
      return "$" + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
  //#endregion

  //#region FetchData

  const getAllergensPromise = () => {
    GetAllergens().then((response) => {
      if (response !== null && typeof response === "object")
        setAllergenList(response);
    });
  };

  const getCareInstructionsPromise = () => {
    GetCareInstructions().then((response) => {
      if (response !== null && typeof response === "object")
        setCareList(response);
    });
  };

  const getIngredientsPromise = () => {
    GetIngredients().then((response) => {
      if (response !== null && typeof response === "object")
        setIngredientsList(response);
    });
  };
  //#endregion

  //#region style
  const readonlystyleboxsmall = {
    fontWeight: "bold",
  };
  //#endregion

  return (
    <>
      <Modal open={ShowSupplierFetchedModal}>
        <Box
          sx={modalStyle}
          gap={1}
          p={1}
          my={1}
          maxWidth={1000}
          minWidth={1000}
        >
          <div className="p-1">
            <Grid container columns={7} spacing={0.2}>
              <Grid item sx={rowstyle} xs={7}>
                <TextField
                  fullWidth
                  label="Title"
                  disabled={true}
                  defaultValue={SupplierFetchedItem?.title}
                  type="string"
                  placeholder={SupplierFetchedItem?.title}
                />
              </Grid>
              <Grid item sx={rowstyle} xs={7}>
                <TextField
                  disabled
                  fullWidth
                  multiline
                  label="Ingredients"
                  value={SupplierFetchedItem?.ingredients.join(",")}
                ></TextField>
              </Grid>
              <Grid item sx={rowstyle} xs={7}>
                <TextField
                  disabled
                  fullWidth
                  multiline
                  label="May Contain"
                  value={SupplierFetchedItem?.mayContain}
                ></TextField>
              </Grid>
              <Grid item sx={rowstyle} xs={1}>
                <TextField
                  disabled
                  fullWidth
                  multiline
                  label="Energy"
                  value={SupplierFetchedItem?.energyValue}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={readonlystylebox}>
                        {SupplierFetchedItem?.energyUnitOfMeasure}
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              </Grid>
              <Grid item sx={rowstyle} xs={1}>
                <TextField
                  disabled
                  fullWidth
                  multiline
                  label="Protein"
                  value={SupplierFetchedItem?.proteinValue}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={readonlystylebox}>
                        {SupplierFetchedItem?.proteinUnitOfMeasure}
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              </Grid>
              <Grid item sx={rowstyle} xs={1}>
                <TextField
                  disabled
                  fullWidth
                  multiline
                  label="Fat"
                  value={SupplierFetchedItem?.fatValue}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={readonlystylebox}>
                        {SupplierFetchedItem?.fatUnitOfMeasure}
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              </Grid>
              <Grid item sx={rowstyle} xs={1}>
                <TextField
                  disabled
                  fullWidth
                  multiline
                  label="Carbohydrates"
                  value={SupplierFetchedItem?.carbohydratesValue}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={readonlystylebox}>
                        {SupplierFetchedItem?.carbohydratesUnitOfMeasure}
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              </Grid>
              <Grid item sx={rowstyle} xs={1}>
                <TextField
                  disabled
                  fullWidth
                  multiline
                  label="Sugar"
                  value={SupplierFetchedItem?.sugarsValue}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={readonlystylebox}>
                        {SupplierFetchedItem?.sugarsUnitOfMeasure}
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              </Grid>
              <Grid item sx={rowstyle} xs={1}>
                <TextField
                  disabled
                  fullWidth
                  multiline
                  label="Sodium"
                  value={SupplierFetchedItem?.sodiumValue}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end" sx={readonlystylebox}>
                        {SupplierFetchedItem?.sodiumUnitOfMeasure}
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              </Grid>
              <Grid item sx={rowstyle} xs={7}>
                <TextField
                  disabled
                  fullWidth
                  multiline
                  label="Care Instructions"
                  value={SupplierFetchedItem?.careInstructions.join(", ")}
                ></TextField>
              </Grid>

              <Grid item sx={rowstyle} xs={5}>
                <TextField
                  disabled
                  fullWidth
                  multiline
                  label="Dietry"
                  value={SupplierFetchedItem?.dietary.join(", ")}
                ></TextField>
              </Grid>

              <Grid item sx={rowstyle} xs={1}>
                <FormControlLabel
                  control={<Checkbox checked={updatevendTags} />}
                  onChange={handleUpdateVendTagsChange}
                  label="Update Vend Tags"
                />
              </Grid>
            </Grid>

            <Button
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={() => {
                value.ingredients = SupplierFetchedItem.ingredients;
                value.carbohydratesPer100 =
                  SupplierFetchedItem.carbohydratesValue;
                value.energyPer100 = SupplierFetchedItem.energyValue;
                value.proteinPer100 = SupplierFetchedItem.proteinValue;
                value.totalFatPer100 = SupplierFetchedItem.fatValue;
                value.sugarsPer100 = SupplierFetchedItem.sugarsValue;
                value.sodiumPer100 = SupplierFetchedItem.sodiumValue;
                value.mayContain = SupplierFetchedItem.mayContain;
                value.careInstructions = SupplierFetchedItem.careInstructions;
                value.servingSize = SupplierFetchedItem.servingSize;
                value.servingUnit = SupplierFetchedItem.servingUnit;
                value.isFood = true;
                value.supplierCode = supplierCode;
                saveitem();
              }}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={() => setSupplierFetchedModal(false)}
              startIcon={<CancelIcon />}
            >
              cancel
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal open={editmode}>
        <Box
          sx={modalStyle}
          gap={1}
          p={1}
          my={1}
          maxWidth={1200}
          minWidth={1200}
        >
          <div className="p-1">
            <TextField
              sx={{ m: 1, width: 400 }}
              id="edit-productName"
              InputProps={{
                readOnly: true,
              }}
              label="Product"
              defaultValue={
                value?.productName + " - (" + value?.productSku + ")"
              }
            ></TextField>
            <TextField
              sx={{ m: 1, width: 100 }}
              id="filled-number"
              label="Serving Size"
              defaultValue={value?.servingSize}
              onChange={(e) => {
                if (e.target.value != null) {
                  value.servingSize = Number(e.target.value);
                  setValue(value);
                }
              }}
              type="number"
              InputProps={{
                endAdornment: <InputAdornment position="end">g</InputAdornment>,
              }}
              placeholder={value?.servingUnit}
            />
          </div>

          <div className="p-1">
            <TextField
              sx={{ m: 1 }}
              id="edit-description"
              fullWidth
              label="Description"
              multiline
              onChange={(e) => {
                if (e.target.value != null) {
                  value.productDescription = e.target.value;
                  setValue(value);
                }
              }}
              rows={1}
              defaultValue={value?.productDescription}
            ></TextField>
          </div>

          <Autocomplete
            sx={{ m: 1 }}
            fullWidth
            multiple
            id="tags-standard"
            options={ingredientsList.map(
              (ingredient) => ingredient.ingredientName
            )}
            getOptionLabel={(option) => option}
            onChange={(event, values) => {
              console.log(values);
              value.ingredients = values;
              setValue(value);
            }}
            freeSolo
            defaultValue={value?.ingredients}
            disableCloseOnSelect
            renderOption={(props, option, { selected }) => (
              <MenuItem
                key={option}
                value={option}
                sx={{ justifyContent: "space-between" }}
                {...props}
              >
                {option}
                {selected ? <CheckIcon color="info" /> : null}
              </MenuItem>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Ingredient list"
                placeholder="Ingredient"
              />
            )}
          />

          <Autocomplete
            sx={{ m: 1 }}
            fullWidth
            multiple
            id="tags-standard"
            freeSolo
            options={allergenList.map((allergen) => allergen.allergenName)}
            getOptionLabel={(option) => option}
            defaultValue={value?.mayContain}
            onChange={(e, v) => {
              value.mayContain = v;
              setValue(value);
            }}
            disableCloseOnSelect
            renderOption={(props, option, { selected }) => (
              <MenuItem
                key={option}
                value={option}
                sx={{ justifyContent: "space-between" }}
                {...props}
              >
                {option}
                {selected ? <CheckIcon color="info" /> : null}
              </MenuItem>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="May contain list"
                placeholder="allergens"
              />
            )}
          />

          <Autocomplete
            sx={{ m: 1 }}
            fullWidth
            multiple
            freeSolo
            id="tags-standard"
            options={carelist.map((care) => care.instruction)}
            getOptionLabel={(option) => option}
            defaultValue={value?.careInstructions}
            onChange={(e, v) => {
              value.careInstructions = v;
              setValue(value);
            }}
            disableCloseOnSelect
            renderOption={(props, option, { selected }) => (
              <MenuItem
                key={option}
                value={option}
                sx={{ justifyContent: "space-between" }}
                {...props}
              >
                {option}
                {selected ? <CheckIcon color="info" /> : null}
              </MenuItem>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Care Instructions"
                placeholder="care instruction"
              />
            )}
          />

          <Grid container columns={1} spacing={0.2}>
            <Grid item sx={rowstyle}>
              <TextField
                id="filled-number"
                fullWidth
                label="Energy"
                defaultValue={value?.energyPer100}
                onChange={(e) => {
                  console.log(e.target.value);
                  value.energyPer100 = Number(e.target.value);
                  setValue(value);
                }}
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {value?.energyUnit}
                    </InputAdornment>
                  ),
                }}
                placeholder={value?.servingUnit}
              />
            </Grid>
            <Grid item sx={rowstyle}>
              <TextField
                id="filled-number"
                fullWidth
                label="Protein"
                defaultValue={value?.proteinPer100}
                onChange={(e) => {
                  console.log(e.target.value);
                  value.proteinPer100 = Number(e.target.value);
                  setValue(value);
                }}
                type="number"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {value?.proteinUnit}
                    </InputAdornment>
                  ),
                }}
                placeholder={value?.servingUnit}
              />
            </Grid>
            <Grid item sx={rowstyle}>
              <TextField
                id="filled-number"
                fullWidth
                label="fat"
                defaultValue={value?.totalFatPer100}
                type="number"
                onChange={(e) => {
                  console.log(e.target.value);
                  value.totalFatPer100 = Number(e.target.value);
                  setValue(value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {value?.totalFatUnit}
                    </InputAdornment>
                  ),
                }}
                placeholder={value?.servingUnit}
              />
            </Grid>
          </Grid>

          <Grid container columns={1} spacing={0.2}>
            <Grid item sx={rowstyle}>
              <TextField
                id="filled-number"
                fullWidth
                label="Carbohydrates"
                defaultValue={value?.carbohydratesPer100}
                type="number"
                onChange={(e) => {
                  console.log(e.target.value);
                  value.carbohydratesPer100 = Number(e.target.value);
                  setValue(value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {value?.carbohydrateUnit}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item sx={rowstyle}>
              <TextField
                id="filled-number"
                fullWidth
                label="Sugars"
                defaultValue={value?.sugarsPer100}
                type="number"
                onChange={(e) => {
                  console.log(e.target.value);
                  value.sugarsPer100 = Number(e.target.value);
                  setValue(value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {value?.sugarsUnit}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item sx={rowstyle}>
              <TextField
                id="filled-number"
                fullWidth
                label="Sodium"
                defaultValue={value?.sodiumPer100}
                type="number"
                onChange={(e) => {
                  console.log(e.target.value);
                  value.sodiumPer100 = Number(e.target.value);
                  setValue(value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {value?.sodiumUnit}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <ButtonGroup
            variant="outlined"
            aria-label="Loading button group"
            sx={{ m: 1 }}
          >
            <Button
              variant="contained"
              onClick={saveitem}
              startIcon={<SaveIcon />}
            >
              Save
            </Button>

            <Button
              variant="outlined"
              onClick={() => setEditMode(false)}
              startIcon={<CancelIcon />}
            >
              cancel
            </Button>
          </ButtonGroup>
        </Box>
      </Modal>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={openWaringModal}
        onClose={() => setWarningModalOpenState(false)}
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Information missing
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Please specify Batch Number and best before date
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              setWarningModalOpenState(false);
            }}
            startIcon={<CheckIcon />}
          >
            ok
          </Button>
        </Box>
      </Modal>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={wait}
      >
        <Typography>
          <h1>Please wait Refreshing from Vend </h1>
        </Typography>
        <br />
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box gap={1} p={1} my={1} maxWidth={1000} minWidth={1000}>
        <ButtonGroup
          variant="outlined"
          aria-label="Loading button group"
          sx={{ m: 0, padding: 0, maxWidth: 1000, minWidth: 1000 }}
        >
          <Button
            variant="outlined"
            disabled={value == null}
            onClick={async () => {
              await handleEditClicked();
            }}
            startIcon={<EditIcon />}
          >
            Edit
          </Button>
          <Divider orientation="vertical" variant="middle" />

          <TextField
            fullWidth
            id="labels"
            label="Number of Labels"
            type="number"
            value={NumberofLabels}
            onChange={(e) => setNumberofLabels(parseInt(e.target.value))}
          ></TextField>
          <Divider orientation="vertical" variant="middle" />
          <Button
            variant="contained"
            onClick={() => {
              printLabels(
                value,
                NumberofLabels,
                batchNumber,
                bestbeforedate,
                labelconfiguration,
                servingsPerPackage
              );
            }}
            startIcon={<PrintIcon />}
          >
            Print
          </Button>
          <Divider orientation="vertical" variant="middle" />

          <Button
            disabled={
              value?.printHistory?.length == 0 || value?.printHistory == null
            }
            variant="outlined"
            onClick={() => {}}
            startIcon={<HistoryIcon />}
          ></Button>
          <TextField
            id="Supplier-Code"
            label="Supplier Code"
            value={supplierCode}
            onChange={(e) => {
              if (e.target.value != null) {
                console.log(e.target.value);
                setSupplierCode(e.target.value);
              }
            }}
            InputProps={{}}
          />
          <Divider orientation="vertical" variant="middle" />
          <Button
            disabled={supplierCode?.trim() == ""}
            variant="outlined"
            onClick={() => {
              FetchSupplierdetails(value.supplierId);
            }}
            startIcon={<DownloadIcon />}
          ></Button>
        </ButtonGroup>

        <Box>
          <Grid container columns={11} spacing={0.5} sx={{ m: 0, padding: 1 }}>
            <Grid item xs={6}>
              <Autocomplete
                id="Id"
                options={productList}
                onChange={(event, newValue) => refreshreadonlydata(newValue)}
                getOptionLabel={(option) =>
                  `${option.productName} | ${option.supplier} (${option.productSku})`
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField {...params} label="Product" />
                )}
                onOpen={handleAutoCompleteOpen}
                onClose={() => setopenAutoComplete(false)}
                open={openAutoComplete}
              ></Autocomplete>
            </Grid>
            <Grid item xs={1}>
              <Button
                variant="text"
                onClick={() => {
                  syncItem(value);
                }}
                startIcon={<RefreshIcon />}
              >
                Sync
              </Button>
            </Grid>
            <Grid item xs={2}>
              <TextField
                disabled
                value={value?.productSku}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="end" sx={readonlystylebox}>
                      sku
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </Grid>
            <Grid item xs={2}>
              <TextField
                disabled
                value={currencyFormat(value?.price)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={readonlystylebox}>
                      Price
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </Grid>
            <Grid item xs={11}>
              <TextField
                disabled
                fullWidth
                multiline
                value={value?.productDescription ?? ""}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={readonlystylebox}>
                      Description:
                    </InputAdornment>
                  ),
                }}
              ></TextField>
            </Grid>
            <Grid item xs={11}>
              {value?.isFood && (
                <TextField
                  disabled
                  fullWidth
                  multiline
                  value={value?.ingredients.join(",")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={readonlystylebox}>
                        Ingredients:
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              )}
            </Grid>
            <Grid item xs={11}>
              {value?.isFood && (
                <TextField
                  disabled
                  fullWidth
                  multiline
                  value={value?.mayContain.join(",")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={readonlystylebox}>
                        May Contain:
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              )}
            </Grid>
            <Grid item xs={11}>
              {value?.isFood && (
                <TextField
                  disabled
                  fullWidth
                  multiline
                  value={value?.contains.join(",")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={readonlystylebox}>
                        Contains:
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              )}
            </Grid>
            <Grid item xs={11}>
              {value?.isFood && (
                <TextField
                  disabled
                  fullWidth
                  multiline
                  value={value?.careInstructions.join(",")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={readonlystylebox}>
                        Care Instructions:
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              )}
            </Grid>
            <Grid item xs={2}>
              {value?.isFood && (
                <TextField
                  onChange={packageWeightChangedEvent}
                  id="packedWeight"
                  label="Packed Weight"
                  type="number"
                  value={packedsize}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">g</InputAdornment>
                    ),
                  }}
                ></TextField>
              )}
            </Grid>
            <Grid item xs={2}>
              {value?.isFood && (
                <TextField
                  id="BatchNumber"
                  label="Batch Number"
                  type="string"
                  onChange={(e) => {
                    console.log("changing");
                    console.log(e.target.value);
                    setBatchNumber(e.target.value);
                    console.log(batchNumber);
                  }}
                  value={batchNumber}
                  required
                ></TextField>
              )}
            </Grid>
            <Grid item xs={2}>
              {value?.isFood && (
                <TextField
                  id="BestBefore"
                  label="Best Before"
                  type="string"
                  value={bestbeforedate}
                  onChange={(e) => setbestbeforedate(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">mm/yy</InputAdornment>
                    ),
                  }}
                  required
                ></TextField>
              )}
            </Grid>
            <Grid item xs={3}>
              {value?.isFood && (
                <TextField
                  disabled
                  fullWidth
                  type="number"
                  value={value?.servingSize}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={readonlystylebox}>
                        serving size:
                      </InputAdornment>
                    ),

                    endAdornment: (
                      <InputAdornment
                        position="start"
                        sx={readonlystyleboxsmall}
                      >
                        {value?.servingUnit}
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              )}
            </Grid>
            <Grid item xs={2}>
              {value?.isFood && (
                <TextField
                  disabled
                  fullWidth
                  type="number"
                  value={servingsPerPackage.toFixed(2)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" sx={readonlystylebox}>
                        Servings:
                      </InputAdornment>
                    ),
                  }}
                ></TextField>
              )}
            </Grid>
          </Grid>
        </Box>

        <br></br>
        <br></br>

        {value?.isFood && (
          <Grid paddingLeft={2} container spacing={2}>
            <Grid item xs={8} border={1}>
              <Typography>Nutrition Information</Typography>
            </Grid>
            <Grid item xs={2} border={1}>
              <Typography textAlign="center">Quantity Per Serving</Typography>
            </Grid>
            <Grid item xs={2} border={1}>
              <Typography textAlign="center">Quantity Per 100g</Typography>
            </Grid>
            <Grid item xs={8} border={1}>
              <Typography>Energy {value?.energyUnit} </Typography>
            </Grid>
            <Grid item xs={2} border={1}>
              <Typography>{value?.energyPerServing.toFixed(2)}</Typography>
            </Grid>
            <Grid item xs={2} border={1}>
              <Typography>{value?.energyPer100.toFixed(2)}</Typography>
            </Grid>
            <Grid item xs={8} border={1}>
              <Typography>Protein {value?.proteinUnit}</Typography>
            </Grid>
            <Grid item xs={2} border={1}>
              <Typography>{value?.proteinPerServing.toFixed(2)}</Typography>
            </Grid>
            <Grid item xs={2} border={1}>
              <Typography>{value?.proteinPer100.toFixed(2)}</Typography>
            </Grid>
            <Grid item xs={8} border={1}>
              <Typography>Fat {value?.totalFatUnit}</Typography>
            </Grid>
            <Grid item xs={2} border={1}>
              <Typography>{value?.totalFatPerServing.toFixed(2)}</Typography>
            </Grid>
            <Grid item xs={2} border={1}>
              <Typography>{value?.totalFatPer100.toFixed(2)}</Typography>
            </Grid>
            <Grid item xs={8} border={1}>
              <Typography>Carbohydrates {value?.carbohydrateUnit}</Typography>
            </Grid>
            <Grid item xs={2} border={1}>
              <Typography>
                {value?.carbohydratesPerServing.toFixed(2)}
              </Typography>
            </Grid>
            <Grid item xs={2} border={1}>
              <Typography>{value?.carbohydratesPer100.toFixed(2)}</Typography>
            </Grid>
            <Grid item xs={8} border={1}>
              <Typography>Sugar {value?.sugarsUnit}</Typography>
            </Grid>
            <Grid item xs={2} border={1}>
              <Typography>{value?.sugarsPerServing.toFixed(2)}</Typography>
            </Grid>
            <Grid item xs={2} border={1}>
              <Typography>{value?.sugarsPer100.toFixed(2)}</Typography>
            </Grid>
            <Grid item xs={8} border={1}>
              <Typography>Sodium {value?.sodiumUnit}</Typography>
            </Grid>
            <Grid item xs={2} border={1}>
              <Typography>{value?.sodiumPerServing.toFixed(2)}</Typography>
            </Grid>
            <Grid item xs={2} border={1}>
              <Typography>{value?.sodiumPer100.toFixed(2)}</Typography>
            </Grid>
          </Grid>
        )}
        <br />
      </Box>
    </>
  );
}
