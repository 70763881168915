import { styled } from "@mui/system";
import { Paper } from "@mui/material";
 
export const rowstyle = {
  p: 0.5,
  m: 1,
  gap: 1,

  maxWidth: 360,

  bgcolor: "background.pape",
  borderRadius: 2,
};
export const readonlystylebox = {
  fontWeight: "bold",
};

export const modalStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const Item = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));
