import { Autocomplete, Box, Grid, TextField } from "@mui/material";
import { printAuditViewModel } from "../api/product";
import React from "react";

export function PrintLogDetails() {
  const  [printlogs, setPrintLigs] = React.useState<printAuditViewModel[]>([]);
  const [selectedLog,setSelectedlog] = React.useState<printAuditViewModel> ({});
  const [openAutoComplete, setopenAutoComplete] =
  React.useState<boolean>(false);
  const handleIngredientAutoCompleteOpen = () => {
    if (printlogs != null) {
      setopenAutoComplete(true);
    }
  };
  return (
    <>
      <Box gap={1} p={1} my={1} maxWidth={900} minWidth={900}>
      <Grid container columns={4} spacing={0.5} padding={1}>
      <Grid item xs={4}>
              <Autocomplete
                id="Id"
                options={printlogs}
                onChange={(event, newValue) => {
                  console.log(newValue);
                  console.log(event);
                  setSelectedlog(newValue);
                  console.log(selectedLog);
                }}
                getOptionLabel={(option) => option.printedOn}
                renderInput={(params) => (
                  <TextField {...params} label="Print Log" />
                )}
                onOpen={handleIngredientAutoCompleteOpen}
                onClose={() => setopenAutoComplete(false)}
                open={openAutoComplete}
              ></Autocomplete>
            </Grid>
      </Grid>

      </Box>
    </>
  );
}
