import React from "react";
import {
  allergenViewModel,
  simpleProductViewModel,
  supplierViewModel,
} from "../api/product";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import CheckIcon from "@mui/icons-material/Check";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { GetAllergens, GetSuppliers, SaveSupplier } from "../utils/ApiUtils";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { indigo } from "@mui/material/colors";
import MenuItem from "@mui/material/MenuItem";
export default function SupplierDetails() {
  const [supplierlist, SetSupplierList] = React.useState<supplierViewModel[]>(
    []
  );

  const [selectedSupplier, setSelectedSupplier] =
    React.useState<supplierViewModel>({});
  const [openAutoComplete, setopenAutoComplete] =
    React.useState<boolean>(false);
  const [allergenList, setAllergenList] = React.useState<
    Array<allergenViewModel>
  >([]);

  const handleSupplierAutoCompleteOpen = () => {
    if (supplierlist != null) {
      setopenAutoComplete(true);
    }
  };

  React.useEffect(() => {
    GetAllergens().then((response) => {
      if (response !== null && typeof response === "object")
        setAllergenList(response);
    });
  }, [setAllergenList]);
  React.useEffect(() => {
    GetSuppliers().then((response) => {
      if (response !== null && typeof response === "object")
        SetSupplierList(response);
    });
  }, [SetSupplierList]);
  const saveitem = () => {
    SaveSupplier(selectedSupplier);
  };
  return (
    <>
      <Box gap={1} p={1} my={1} maxWidth={900} minWidth={900}>
        <Grid container columns={4} spacing={1.5} padding={2}>
          <Grid item xs={1}>
            <Button
              variant="contained"
              onClick={saveitem}
              startIcon={<SaveIcon />}
            >
              Save
            </Button>
          </Grid>

          <Grid item xs={4}>
            <Autocomplete
              id="Id"
              options={supplierlist}
              onChange={(event, newValue) => {
                console.log(newValue);
                console.log(event);
                setSelectedSupplier(newValue);
                console.log(selectedSupplier);
              }}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="Supplier" />
              )}
              onOpen={handleSupplierAutoCompleteOpen}
              onClose={() => setopenAutoComplete(false)}
              open={openAutoComplete}
            ></Autocomplete>
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              key={selectedSupplier?.name}
              fullWidth
              multiple
              id="ingredient-allergens"
              freeSolo
              
              options={allergenList.map((allergen) => allergen.allergenName)}
              getOptionLabel={(option) => option}
              defaultValue={selectedSupplier?.allergens}
              onChange={(e, v) => {
                console.log(v);
                selectedSupplier.allergens = v;
                console.log(selectedSupplier.allergens);
                setSelectedSupplier(selectedSupplier);
                console.log(selectedSupplier);
              }}
              disableCloseOnSelect
              renderOption={(props, option, { selected }) => (
                <MenuItem
                  key={option}
                  value={option}
                  sx={{ justifyContent: "space-between" }}
                  {...props}
                >
                  {option}
                  {selected ? <CheckIcon color="info" /> : null}
                </MenuItem>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="May contain"
                  placeholder="May contain"
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} size="small">
                <TableHead sx={{ backgroundColor: indigo[500], color: 0 }}>
                  <TableRow>
                    <TableCell sx={{ color: "White" }}>Products</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody key="products">
                  {selectedSupplier.products?.map(
                    (data: simpleProductViewModel) => (
                      <TableRow
                        key={data.productName}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {data.productName}
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
 
