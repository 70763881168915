import WelcomeName from "./WelcomeName";
import SignInSignOutButton from "./SignInSignOutButton";

import {
  AppBar,
  Container,
  Toolbar,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Navigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: theme.spacing(5),
    display: "flex",
  },

  link: {
    textDecoration: "none",
    color: "white",
    fontSize: "20px",
    marginLeft: theme.spacing(20),
    "&:hover": {
      color: "yellow",
      borderBottom: "1px solid white",
    },
  },
}));

function NavBar() {
  const classes = useStyles();
  const pages = ["/Bagging", "/Suppliers", "/Ingredients" ];
  return (
    <div style={{ flexGrow: 1 }}>
     
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              {pages.map((page) => (
                <Button
                  component={Link}
                  to=  {page}
                  sx={{ my: 2, color: "white", display: "block" }}
                >
                  { page.replace("/","")}
                </Button>
              ))}
            </Box>
           
            <WelcomeName />
            <SignInSignOutButton />
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

export default NavBar;
