import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import SupplierDetails from "../ui-components/SupplierDetails";
import Typography from "@mui/material/Typography";

export default function Suppliers() {
return(
    <>
    <AuthenticatedTemplate>
    
       <SupplierDetails></SupplierDetails>
    </AuthenticatedTemplate>
    <UnauthenticatedTemplate>
        <Typography variant="h6" align="center">
          Please sign-in to see your profile information.
        </Typography>
      </UnauthenticatedTemplate>
    </>
);

}
