import {
    allergenResults,
    careInstructructionsResults,
    configurationViewModel,
    ingredientResults,
    ingredientViewModel,
    printAuditViewModel,
    productResults,
    productViewModel,
    supplierIngredientsViewModel,
    supplierResults,
    supplierViewModel,
  } from "../api/product";
import { msalInstance } from "../index";
import { loginRequest, graphConfig } from "../authConfig";
import axios, { AxiosResponse } from "axios";
 


export async function GetConfig() {
    axios.defaults.baseURL = process.env.REACT_APP_API_BASEURL;
    const account = msalInstance.getActiveAccount();
    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account,
      });
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.accessToken}`;

     return axios
      .get<configurationViewModel>("/configuration")
      .then((res) => {
        
       return  res.data;
      })
      .catch((err) => {
        console.log(err);
      });
    
}

export async function GetIngredients() {
    axios.defaults.baseURL = process.env.REACT_APP_API_BASEURL;
    const account = msalInstance.getActiveAccount();
    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account,
      });
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.accessToken}`;
      return axios.get<ingredientResults>("/ingredients")
      .then ((res)=>{return res.data.ingredients;})
      .catch((err)=>console.log(err));
}
export async function GetProductsList () {
    axios.defaults.baseURL = process.env.REACT_APP_API_BASEURL;
    const account = msalInstance.getActiveAccount();
    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account,
      });
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.accessToken}`;
    return axios.get<productResults>("/products")
     .then ((res=> {
        return res.data.products
     }))
     .catch((err) => {
        console.log(err);
      });
}


export async function GetAllergens () {
    axios.defaults.baseURL = process.env.REACT_APP_API_BASEURL;
    const account = msalInstance.getActiveAccount();
    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account,
      });
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.accessToken}`;
    return axios.get<allergenResults>("/allergens")
     .then ((res=> {
        return res.data.allergens
     }))
     .catch((err) => {
        console.log(err);
      });
}

export async function GetSuppliers(){
  axios.defaults.baseURL = process.env.REACT_APP_API_BASEURL;
  const account = msalInstance.getActiveAccount();
  const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${response.accessToken}`;
  return axios.get<supplierResults>("/suppliers")
   .then ((res=> {
      return res.data.suppliers
   }))
   .catch((err) => {
      console.log(err);
    });
}

export async function GetCareInstructions () {
    axios.defaults.baseURL = process.env.REACT_APP_API_BASEURL;
    const account = msalInstance.getActiveAccount();
    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account,
      });
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.accessToken}`;
    return axios.get<careInstructructionsResults>("/careinstructions")
     .then ((res=> {
        return res.data.careInstructions
     }))
     .catch((err) => {
        console.log(err);
      });
}


export async function GetSupplierIngredients( supplierid :string , suppliercode :string  ):Promise<supplierIngredientsViewModel>{
  axios.defaults.baseURL = process.env.REACT_APP_API_BASEURL;
  const account = msalInstance.getActiveAccount();
  const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${response.accessToken}`;
  const res : AxiosResponse<supplierIngredientsViewModel> =  await axios.get<supplierIngredientsViewModel>("/supplier/"+supplierid+"/"+ suppliercode);
  const responsedata  :supplierIngredientsViewModel = res.data;
  return responsedata;
    
}
export async function Syncproducts(){
  axios.defaults.baseURL = process.env.REACT_APP_API_BASEURL;
  const account = msalInstance.getActiveAccount();
  const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${response.accessToken}`;
    axios.defaults.headers.common['Content-Type']= 'application/json';
    axios.defaults.headers.common['Content-Type']= 'application/json';
    return axios.post("/vend/sync")
    .then ((res=> {
            return res; 
            console.log("ok");
      }));
    }

    export async function updateLabels(){
      axios.defaults.baseURL = process.env.REACT_APP_API_BASEURL;
      const account = msalInstance.getActiveAccount();
      const response = await msalInstance.acquireTokenSilent({
          ...loginRequest,
          account: account,
        });
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response.accessToken}`;
        axios.defaults.headers.common['Content-Type']= 'application/json';
        axios.defaults.headers.common['Content-Type']= 'application/json';
        return axios.post("/labels/update")
        .then ((res=> {
                return res; 
                console.log("ok");
          }));
        }


export async function saveproduct (item :productViewModel) {
  axios.defaults.baseURL = process.env.REACT_APP_API_BASEURL;
    const account = msalInstance.getActiveAccount();
    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account,
      });
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.accessToken}`;
      axios.defaults.headers.common['Content-Type']= 'application/json';
    return axios.post<productViewModel>("/products",item)
    .then ((res=> {
            return res; 
            console.log("ok");
      }
   ))
   .catch((err) => {
    alert(err.response.data.error);
     if(err.response){
      console.log(err.response.status);
      
     }
      console.log(err);
    });
}


export async function PrintAuditLog (item : printAuditViewModel){
  axios.defaults.baseURL = process.env.REACT_APP_API_BASEURL;
  const account = msalInstance.getActiveAccount();
  const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${response.accessToken}`;
    axios.defaults.headers.common['Content-Type']= 'application/json';
  return axios.post<ingredientViewModel>("/printlog",item)
  .then ((res=> {
    return res; 
    }
 ))
 .catch((err) => {
    console.log(err);
  });
}

export   function Getusername () {
  const account = msalInstance.getActiveAccount();
 return account.username;
}
export async function SaveSupplier(item: supplierViewModel){
  axios.defaults.baseURL = process.env.REACT_APP_API_BASEURL;
  const account = msalInstance.getActiveAccount();
  const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${response.accessToken}`;
    axios.defaults.headers.common['Content-Type']= 'application/json';
  return axios.post<ingredientViewModel>("/suppliers",item)
  .then ((res=> {
    return res; 
    }
 ))
 .catch((err) => {
    console.log(err);
  });
}
export async function saveIngredient (item :ingredientViewModel) {
  axios.defaults.baseURL = process.env.REACT_APP_API_BASEURL;
    const account = msalInstance.getActiveAccount();
    const response = await msalInstance.acquireTokenSilent({
        ...loginRequest,
        account: account,
      });
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.accessToken}`;
      axios.defaults.headers.common['Content-Type']= 'application/json';
    return axios.post<ingredientViewModel>("/ingredients",item)
    .then ((res=> {
      return res; 
      }
   ))
   .catch((err) => {
      console.log(err);
    });
}