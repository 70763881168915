
import CheckIcon from "@mui/icons-material/Check";
import SaveIcon from "@mui/icons-material/Save";
import RefreshIcon from "@mui/icons-material/Refresh";
import React from "react";
import { allergenViewModel, ingredientViewModel, simpleProductViewModel } from "../api/product";
import { GetAllergens, GetIngredients, saveIngredient, updateLabels } from "../utils/ApiUtils";

import { Autocomplete, Backdrop, Box, Button, CircularProgress, Grid, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material";
import { indigo } from "@mui/material/colors";


export function IngredientDetails () {
    const [editingredients, setEditIngredientsList] = React.useState<
    ingredientViewModel[]
  >([]);
  const [wait, setWait] = React.useState(false);
  const [allergenList, setAllergenList] = React.useState<
    Array<allergenViewModel>
  >([]);
  React.useEffect(() => {
    GetAllergens().then((response) => {
      if (response !== null && typeof response === "object")
        setAllergenList(response);
    });
  }, [setAllergenList]);

  React.useEffect(() => {
    GetIngredients().then((response) => {
      if (response !== null && typeof response === "object")
        setEditIngredientsList(response);
    });
  }, [setEditIngredientsList]);

  const [openAutoComplete, setopenAutoComplete] =
    React.useState<boolean>(false);
  const [selectedIngredient, setSelectedIngredient] =
    React.useState<ingredientViewModel>({});

  const handleIngredientAutoCompleteOpen = () => {
    if (editingredients != null) {
      setopenAutoComplete(true);
    }
  };
  const updatelabeltemplates = () => {
    setWait(true);
    updateLabels().then((response) => {
      setWait(false);
    });
  };
  const saveitem = () => {
    saveIngredient(selectedIngredient);
  };

  return (
    <>
   
    <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={wait}
      >
        <Typography>
          <h1>Please wait Refreshing from Vend </h1>
        </Typography>
        <br />
        <CircularProgress color="inherit" />
      </Backdrop>
      <div>
        <Box gap={1} p={1} my={1} maxWidth={900} minWidth={900}>
          <Grid container columns={4} spacing={0.5} padding={1}>
            <Grid item xs={1}>
              <Button
                variant="contained"
                onClick={saveitem}
                startIcon={<SaveIcon />}
              >
                Save
              </Button>
            </Grid>

            <Grid item xs={2}></Grid>
            <Grid item xs={1}>
              <Button
                onClick={updatelabeltemplates}
                startIcon={<RefreshIcon />}
              >
                regenerate all labels
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Autocomplete
                id="Id"
                options={editingredients}
                onChange={(event, newValue) => {
                  console.log(newValue);
                  console.log(event);
                  setSelectedIngredient(newValue);
                  console.log(selectedIngredient);
                }}
                getOptionLabel={(option) => option.ingredientName}
                renderInput={(params) => (
                  <TextField {...params} label="Ingredient" />
                )}
                onOpen={handleIngredientAutoCompleteOpen}
                onClose={() => setopenAutoComplete(false)}
                open={openAutoComplete}
              ></Autocomplete>
            </Grid>

            <Grid item xs={4}>
              <Autocomplete
                key={selectedIngredient?.ingredientName}
                fullWidth
                multiple
                id="ingredient-allergens"
                freeSolo
                options={allergenList.map((allergen) => allergen.allergenName)}
                getOptionLabel={(option) => option}
                defaultValue={selectedIngredient?.allergens}
                onChange={(e, v) => {
                  console.log(v);
                  selectedIngredient.allergens = v;
                  console.log(selectedIngredient.allergens);
                  setSelectedIngredient(selectedIngredient);
                  console.log(selectedIngredient.allergens);
                }}
                disableCloseOnSelect
                renderOption={(props, option, { selected }) => (
                  <MenuItem
                    key={option}
                    value={option}
                    sx={{ justifyContent: "space-between" }}
                    {...props}
                  >
                    {option}
                    {selected ? <CheckIcon color="info" /> : null}
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Allergens"
                    placeholder="allergens"
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small">
                  <TableHead sx={{backgroundColor: indigo[500] , color:0}}>
                    <TableRow>
                      <TableCell sx={{color:"White"}}>Products</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedIngredient.products?.map(
                      (data: simpleProductViewModel) => (
                        <TableRow
                          key={data.productName}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {data.productName}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </div>
     
    </>
  );
}