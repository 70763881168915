import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
  } from "@azure/msal-react";
   
  import Typography from "@mui/material/Typography";
   
  
  import { productViewModel } from "../api/product";
  import { useState } from "react";
  import {PrintLogDetails} from "../ui-components/PrintLogComponent";
  
   
  
  export function PrintLog() {
    const [formdata, setformdata] = useState<productViewModel | null>(null);
    function resetdata(data?: productViewModel) {
      console.log("form data returned");
      console.log(data);
      if (data === undefined)   
      setformdata(data);
    }
    return (
      <>
        <AuthenticatedTemplate>
        
         <PrintLogDetails></PrintLogDetails>
         
        </AuthenticatedTemplate>
  
        <UnauthenticatedTemplate>
          <Typography variant="h6" align="center">
            Please sign-in to see your profile information.
          </Typography>
        </UnauthenticatedTemplate>
      </>
    );
  }
  